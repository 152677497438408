import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Home() {
	const [MyEvent, setMyEvent] = useState({});
	const [Tickets, setTickets] = useState([]);
	const [scannedTickets, setScannedTickets] = useState([]);
	const [totalTickets, setTotalTickets] = useState(0);

	useEffect(() => {
		// Redirect to login if token is not set or expired
		const token = localStorage.getItem("token");
		const expiresIn = localStorage.getItem("expiresIn");

		if (!token || Date.now() >= expiresIn) {
			window.location.href = "/login";
		}

		const checkCameraPermission = async () => {
			try {
				const permission = await navigator.permissions.query({ name: "camera" });
				// console.log("Camera permission:", permission.state);

				permission.onchange = () => {
					// console.log("Camera permission:", permission.state);
				};
			} catch (error) {
				console.error("Error checking camera permission:", error);
			}
		};

		// get myEvent from hangoverparties.sk/events/upcoming
		const getEvent = async () => {
			try {
				const response = await fetch("https://hangoverparties.sk/api/events/upcoming");
				const data = await response.json();
				setMyEvent(data);
				fetch("https://hangoverparties.sk/api/tickets?eventID=" + data.id, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: localStorage.getItem("token"),
					},
				})
					.then((response) => response.json())
					.then(async (ticketData) => {
						// Fetch transaction status for each ticket
						const ticketsWithTransactions = await Promise.all(
							ticketData.map(async (ticket) => {
								if (ticket.transactionId) {
									const transResponse = await fetch(
										`https://hangoverparties.sk/api/transactions/${ticket.transactionId}`,
										{
											headers: {
												Authorization: localStorage.getItem("token"),
											},
										}
									);
									const transData = await transResponse.json();
									return { ...ticket, paid: transData.state === "PAID" };
								}
								return { ...ticket, paid: false };
							})
						);

						// Filter only paid tickets
						const paidTickets = ticketsWithTransactions.filter((ticket) => ticket.paid);
						setTickets(paidTickets);
						setTotalTickets(paidTickets.length);
						const scanned = paidTickets.filter((ticket) => ticket.scannedAt);
						setScannedTickets(scanned);
						console.log("Tickets scanned:", scanned.length);
						console.log("Total paid tickets:", paidTickets.length);
					})
					.catch((error) => {
						console.error("Error fetching ticket:", error);
					});
			} catch (error) {
				console.error("Error getting event data:", error);
			}
		};

		checkCameraPermission();
		getEvent();
	}, []);

	return (
		<div className="flex flex-col min-h-screen p-4 pt-20">
			<h1 className="w-full text-center text-3xl font-bold">Hangover Scanner</h1>
			<div className="flex flex-col justify-around p-10 items-center">
				<div className="flex flex-col justify-center items-center bg-neutral-100 w-full p-2 rounded-lg">
					<h1 className="font-semibold text-lg">Event: {MyEvent.name}</h1>
					<h1 className="font-semibold text-lg">
						Tickets: {scannedTickets.length} / {totalTickets}
					</h1>
				</div>

				<Link to="/scan" className="btn btn-lg btn-neutral my-4 text-center w-full">
					<button className="border-2 border-gray-400 bg-neutral-200 font-bold text-lg rounded-lg p-2 w-full">
						Scan Ticket
					</button>
				</Link>
			</div>
			{/* Spacer to push logout button to bottom */}
			<div className="flex-grow"></div>
			{/* logout button */}
			<div className="flex justify-center mb-8">
				<button
					className="btn btn-lg btn-neutral text-red-600 font-semibold"
					onClick={() => {
						localStorage.removeItem("token");
						window.location.href = "/login";
					}}
				>
					Logout
				</button>
			</div>
		</div>
	);
}

export default Home;
